import React from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import List from "cms-toolbox/List";


import {ChipArrayInput} from "cms-toolbox";
import ListContent from "cms-toolbox/ListContent";
import {Filter} from "react-admin";
import {SearchInput, NumberInput, SelectInput} from "../../cms-toolbox/inputHoc";
//import { article as schema } from "ev-schema";


import Card from '@material-ui/core/Card';

import CardContent from '@material-ui/core/CardContent';
import {makeStyles, withStyles} from '@material-ui/core/styles';


const styles = {
    cardContainer: {
        boxShadow: "none",
        background: "transparent",
        display: "grid",
        border: "none",
        gridTemplateColumns:"repeat(auto-fill,minmax(220px,1fr))",
        gap:"20px",
        maxWidth:"870px"
    },
    card: {

    },
    root: {
        border: "none",
        background: "transparent"
    },
    cardLink:{
        display:"block",
        height:"100%",
        textDecoration:"none",
        "&:hover":{
            background:"rgba(0, 0, 0, 0.04)"
        }
    },
    p:{
        marginTop:"10px"
    }

};

export const ReportsOverview = withStyles(styles)(({classes}) => {
    return (
        <div  className={classes.cardContainer}>


            {[
                {
                    title:"Customers",
                    desc:"Website request form submissions log",
                    href:`/#/customer`
                },
                {
                    title:"Broken links",
                    desc:"Find dead links on pages that link to nonexistent urls",
                    href:`/#/reports/broken-links`
                },
                {
                    title:"Search count",
                    desc:"Aggregated sum of visitor's search queries on the website",
                    href:`/#/reports/search`
                },
                {
                    title:"Category stats",
                    desc:"Frequency charts per category in customer's shopping carts",
                    href:`/#/reports/category-stats`
                },
                {
                    title:"Product score",
                    desc:"Frequency count per product in customer's shopping carts",
                    href:`/#/reports/product-request-count`
                },
                {
                    title:"No address",
                    desc:"Products that have no address",
                    href:`/#/reports/product-addresses`
                },
                {
                    title:"Recently unpublished",
                    desc:"Recently unpublished items",
                    href:`/#/reports/unpublished`
                },
                {
                    title:"Page score",
                    desc:"How many products are shows on pages with 'show catalog' active.",
                    href:`/#/reports/page-stats`
                }
            ].map(({title,desc,href})=><Card key={href} className={classes.card}>
                <a className={classes.cardLink} href={href}>
                <CardContent>
                    <Typography variant="h5">
                        {title}
                    </Typography>
                    <Typography classNames={classes.p} variant="p1" component="p" color="textSecondary">
                        {desc}
                    </Typography>
                </CardContent>
                </a>
            </Card>)}

        </div>)
})


export class StatisticsList extends React.Component {
    render = () => {
        return (
            <List
                title="Visitor search query log"
                {...this.props}
                perPage={50}
                sort={{ field: "count", order: "DESC" }}

            >
                <ListContent

                    {...this.props}
                    showFields={[
                        {
                            source: "id",
                            media: "small"
                        },
                        {
                            source: "count",
                            media: "small"
                        },

                    ]}
                    schema={[
                        {

                            source: "id",
                            type: "text",
                            label:"Search query"
                        },
                        {

                            source: "count",
                            type: "number"
                        }
                    ]}
                />
            </List>
        );
    };
}





const ProductStatsFilter = props => (
    <Filter {...props}>
        <SearchInput alwaysOn source="q" />
        <NumberInput alwaysOn source="months" label={"Months back"} />
    </Filter>
);

export class StatisticsProducts extends React.Component {
    render = () => {
        return (
            <List
                title="Product score"
                filterDefaultValues={{ months: 12 }}
                filters={<ProductStatsFilter />}
                {...this.props}
                perPage={50}
                sort={{ field: "c", order: "DESC" }}

            >
                <ListContent

                    {...this.props}
                    showFields={[
                        {
                            source: "id", media: "small"
                        },
                        {
                            source: "c", media: "small"
                        },
                        {
                            source: "weight", media: "small"
                        },



                    ]}
                    schema={[

                        {

                            source: "c",
                            label:"Times requested",
                            type: "number",

                            sortable:false,
                        },
                        {

                            source: "weight",
                            label:"Product weight",
                            type: "number",

                            sortable:false,
                        },

                        {
                            source: "id",
                            type: "reference",
                            label: "Product",

                            sortable:false,
                            reference: {
                                resource: "product",
                                optionText: "title"
                            }
                        },
                    ]}
                />
            </List>
        );
    };
}

















const ProductAddressesFilter = props => (
    <Filter {...props}>
        <SearchInput alwaysOn source="q" />
        <ChipArrayInput
        alwaysOn
        multiple
        source="coords"
        label="Address status"
        allowEmpty={true}
        choices={[
            { id: "no_evo_id", name: "No Evobooker ID" },
            { id: "no_supplier", name: "No supplier" },
            { id: "no_location", name: "No location in Evobooker" },
            { id: "has_location", name: "Has address" },
        ]}
      />

    </Filter>
);

export class StatisticsAddresses extends React.Component {
    render = () => {
        return (
            <List
                title="No addresses"
                filterDefaultValues={{ 

                 }}
                filters={<ProductAddressesFilter />}
                {...this.props}
                perPage={25}
                sort={{ field: "coords", order: "ASC" }}

            >
                <ListContent

                    {...this.props}
                    showFields={[
                        {
                            source: "id", media: "small"
                        },
                       /* {
                            source: "title", media: "small"
                        },*/
                        {
                            source: "coords", media: "small"
                        },
                        {
                            source: "evo_id", media: "small"
                        },
                    ]}
                    schema={[
                        {

                            source: "coords",
                            label:"Location",

                           // type: "number",

                            sortable:true,

                            render: 'coords'
                        },
                        {

                            source: "evo_id",
                            label:"Evobooker",

                           // type: "number",

                            sortable:false,

                            render: 'evo_url'
                        },

                        {
                            source: "id",
                            type: "reference",
                            label: "Title",
                            sortable:true,
                            //type:"text",
                            reference: {
                                resource: "product",
                                optionText: "title"
                            }
                        },
                    ]}
                />
            </List>
        );
    };
}








export class StatisticsUnpublished extends React.Component {
    render = () => {
        return (
            <List
                title="Recently unpublished"
                {...this.props}
                perPage={50}
                sort={{ field: "unpublish_date", order: "DESC" }}

            >
                <ListContent

                    {...this.props}
                    showFields={[
                        {
                            source: "source", media: "small"
                        },
                        {
                            source: "path", media: "small"
                        },
                        {
                            source: "title", media: "small"
                        },
                        {
                            source: "unpublish_date", media: "small"
                        },




                    ]}
                    schema={[
                        {
                            source: "unpublish_date",
                            label: "Unpublished on",
                            type: "date",
                            sortable:true
                        },
                        {
                            source: "path",
                            label: "Path"
                        },
                        {

                            source: "title",
                            label:"Title",

                            sortable:true,
                        },

                        {
                            source: "source",
                            type: "entity_links",
                            label: "Source"
                        }
                    ]}
                />
            </List>
        );
    };
}





const PageStatsFilter = props => (
    <Filter {...props}>
        <SearchInput alwaysOn source="q" />
    </Filter>
);

export class StatisticsPages extends React.Component {
    render = () => {
        return (
            <List
                title="Empty results"
                filters={<PageStatsFilter />}
                {...this.props}
                perPage={50}
                sort={{ field: "total_count", order: "ASC" }}

            >
                <ListContent

                    {...this.props}
                    showFields={[


                        {
                            source: "id", media: "small"
                        },
                        {
                            source: "category", media: "small"
                        },
                        {
                            source: "system_count", media: "small"
                        },
                        {
                            source: "manual_count", media: "small"
                        },
                        {
                            source: "total_count", media: "small"
                        },



                    ]}
                    schema={[

                        {
                            source: "id",
                            type: "reference",
                            label: "Page",

                            sortable:false,
                            reference: {
                                resource: "page",
                                optionText: "title"
                            }
                        },

                        {
                            source: "category",
                            type: "reference",
                            label: "Category",

                            sortable:false,
                            reference: {
                                resource: "tree",
                                optionText: "text"
                            }
                        },


                        {

                            source: "system_count",
                            label:"Category products",
                            type: "number",

                            sortable:false,
                        },
                        {

                            source: "manual_count",
                            label:"Manual products",
                            type: "number",

                            sortable:false,
                        },
                        {

                            source: "total_count",
                            label:"Total products",
                            type: "number",
                        },


                    ]}
                />
            </List>
        );
    };
}
