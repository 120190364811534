import React from 'react';
import { dataProvider } from 'cms-config';
import BooleanInput from 'cms-toolbox/BooleanInput';
import {
  AreaChart,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { Form } from 'react-final-form';
import { DateInput, SelectInput } from 'react-admin';

import FormControlLabel from '@material-ui/core/FormControlLabel';

import { FieldTitle, useInput, InputProps } from 'ra-core';

import Switch, { SwitchProps } from '@material-ui/core/Switch';

import { structure } from 'ev-schema';

function getMonday(date) {
  const day = date.getUTCDay();
  const diff = day === 0 ? -6 : 1 - day;
  const monday = new Date(date);
  monday.setUTCDate(date.getUTCDate() + diff);
  monday.setUTCHours(0, 0, 0, 0);
  return monday;
}

function getFourWeekRange() {
  const now = new Date();
  const currentMonday = getMonday(now);
  const lastWeekMonday = new Date(currentMonday);
  lastWeekMonday.setUTCDate(currentMonday.getUTCDate() - 7);

  const startMonday = new Date(lastWeekMonday);
  startMonday.setUTCDate(lastWeekMonday.getUTCDate() - 21);

  const endSunday = new Date(lastWeekMonday);
  endSunday.setUTCDate(lastWeekMonday.getUTCDate() + 6);

  return {
    start: startMonday.toISOString().slice(0, 10),
    end: endSunday.toISOString().slice(0, 10),
  };
}

export const StatisticsCategories = () => {
  // On mount, check URL for start/end; fallback to default 4-week range.
  const [_, search] = window.location.hash.split('?');

  let initialFilters = { ...getFourWeekRange(), tree: 'category', expanded:0 };

  if (search) {
    const urlParams = new URLSearchParams(search);
    const urlStart = urlParams.get('start');
    const urlEnd = urlParams.get('end');
    const urlTree = urlParams.get('tree');
    const urlExpanded = urlParams.get('expanded');
    initialFilters = {
      start: urlStart || initialFilters.start,
      end: urlEnd || initialFilters.end,
      tree: urlTree || initialFilters.tree,
      expanded: typeof urlExpanded!== 'undefined'?parseInt(urlExpanded):initialFilters.expanded,
    };
  }

  const [filters, setFilters] = React.useState(initialFilters);
  const [chartData, setChartData] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [colors, setColors] = React.useState([]);
  const [labels, setLabels] = React.useState({});

  // Whenever filters change, update the browser URL (without refresh)
  React.useEffect(() => {
    console.log(filters)
    const params = new URLSearchParams(filters);
    const newUrl =
      (window.location.pathname + window.location.hash).split('?')[0] +
      '?' +
      params.toString();
    window.history.replaceState(null, '', newUrl);
  }, [filters.start, filters.end, filters.tree]);

  // Fetch data when filters change
  React.useEffect(() => {
    const queryString = new URLSearchParams(filters).toString();
    dataProvider('GET_CUSTOM', 'reports/category-stats?' + queryString).then(
      ({ data:{data,menuTree} }) => {



const roots={};

        let catOrder={};
        let catI=0;
        function buildCatOrder(tree,rootCat){
            tree.forEach((node)=>{
                const root=rootCat||node.tid;
                roots[root]=true;
                catOrder[node.tid]={i:catI++,root, title:node.title};
                if(node.children){
                    buildCatOrder(node.children,root)
                }
            })
        }
        buildCatOrder(menuTree)


        const dataByWeek = {};
        const catSet = new Set();

        data.forEach((item) => {
          const week = item.week;
          catSet.add(item.category_id);
          if (!dataByWeek[week]) {
            dataByWeek[week] = { week: String(week) };
          }
         // console.log(item.category_id)
          dataByWeek[week][item.category_id] = item.request_count;
        });

        const categoriesArr = [...catSet].sort((a, b) => {
            const i1=catOrder[a].i;
            const i2=catOrder[b].i;
            if(i1<i2){
                return -1;
            }
            if(i1>i2){
                return 1;
            }
            return 0;
        });


        // Ensure every week has an entry for every category (defaulting to 0)
        const chartDataFixed = Object.values(dataByWeek).map((weekObj) => {
          categoriesArr.forEach((cat) => {
            if (weekObj[cat] === undefined) {
              weekObj[cat] = 0;
            }
          });
          return weekObj;
        });

        const sourceColors=[
            '#8884d8',
            '#82ca9d',
            '#ffc658',
            '#ff7300',
            '#0088FE',
            '#00C49F',
            '#FFBB28',
            '#FF8042',
          ];


          const rootIds=Object.keys(roots).map((tid)=>parseInt(tid)).sort((a,b)=>a-b);

          const newColors=categoriesArr.map((tid)=>{
            const i=catOrder[tid].root;
            const colorIndex=rootIds.findIndex((rootId)=>rootId===i)

            return sourceColors[colorIndex%sourceColors.length]
            })




        setColors(newColors);


        setLabels(catOrder);
        setCategories(categoriesArr);
        setChartData(chartDataFixed);
      },
    );
  }, [filters]);



  return (
    <div>
      <Form
        initialValues={filters}
        onSubmit={(values) => setFilters({ ...values })}
        onChange={(values) => setFilters({ ...values })}
        render={({ handleSubmit, values }) => (
          <form
            onSubmit={handleSubmit}
            onChange={handleSubmit}
            noValidate
            style={{
              display: 'flex',
              gap: '1em',
            }}
          >
            <DateInput source="start" value={values.start} />

            <DateInput source="end" value={values.end} />
            <SelectInput
              onChange={(e) => {
                setFilters({ ...values, tree: e.target.value });
              }}
              value={values.tree}
              alwaysOn
              source="tree"
              label="Tree"
              allowEmpty={false}
              choices={structure.menus.filter(({ id }) =>
                ['category', 'genre', 'occasion'].includes(id),
              )}
            />

            
            <FormControlLabel
                control={
                    <Switch
                       checked={values.expanded}
                        color="primary"
                        onChange={(a)=>{
                          a.preventDefault();
                          a.stopPropagation();
                          setFilters({ ...values, expanded: a.target.checked?1:0 });

                        }}
                    />
                }
                label={
                    <FieldTitle
                        label={'expanded'}
                    />
                }
            />


          </form>
        )}
      />

      <ResponsiveContainer width="100%" height={500}>
        <AreaChart data={chartData} stackOffset={filters.expanded?"expand":null}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="week"
            tickFormatter={(week) => `Week ${parseInt(week.slice(-2))}`}
          />
          <YAxis tickFormatter={(value) => !filters.expanded?value:`${(value * 100).toFixed(0)}%`} />

          <Tooltip
            formatter={(value) => `${value} products requested`}
            labelFormatter={(week) => `Week ${week.slice(-2)}`}
            content={({ payload, label }) => {
              if (!payload || payload.length === 0 || !label) return null;
              let prevColor;
              return (
                <div
                  className="custom-tooltip"
                  style={{
                    backgroundColor: 'white',
                    padding: '0.1em 1em 1em',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
                    fontSize:"12px",
                    maxWidth:"200px"
                  }}
                >
                  <p className="label">{`Week ${label&&parseInt(label.slice(-2))}`}</p>
                  {payload
                    .slice()
                    .reverse()
                    .filter((entry) => entry.value > 0)
                    .map((entry, index) => {
                        let hr;
                        if(prevColor!==entry.color){
                            if(prevColor){
                                hr=true;
                            }
                            prevColor=entry.color;
                            
                        }


                        return (<React.Fragment>
                            {hr?<hr />:(index>0?", ":"")}
                            <span
                              key={index}
                              style={{ color: entry.color, marginBottom: '0.3em' }}
                            >
                              {labels[entry.name]&&labels[entry.name].title}: {entry.value}
                            </span>
                            </React.Fragment>
                          )
                    })}
                </div>
              );
            }}
          />

          {categories.map((cat, idx) => (
            <Area
              key={cat}
              type="monotone"
              dataKey={cat}
              stackId="1"
              stroke={colors[idx % colors.length]}
              fill={colors[idx % colors.length]}
            />
          ))}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

